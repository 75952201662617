<template>
  <ion-page>
    <Header title="Testimonial" />
    <ion-content :fullscreen="true">
      <div class="container">
       <div class="df-testimoni" v-for="(item) in items" :key="item.id">
         <div class="df-date text-sm">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00004 0.333344L9.06004 4.50668L13.6667 5.18001L10.3334 8.42668L11.12 13.0133L7.00004 10.8467L2.88004 13.0133L3.66671 8.42668L0.333374 5.18001L4.94004 4.50668L7.00004 0.333344Z" fill="#F2C94C"/>
          </svg>
          {{ $filters.dateFormat(item.created_at, 'full') }}
        </div>
        <div class="name text-bold">{{ item.user.name }}</div>
        <p>{{ item.content }}</p>
        <div class="df-img">
          <img v-for="(img, i) in item.images" :key="i" :src="img.url" class="thumb">
        </div>
       </div>
      </div>
      <ion-infinite-scroll
        @ionInfinite="getTestimoni"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header,
    IonInfiniteScroll, IonInfiniteScrollContent
  },
  data () {
    return {
      filter: null,
      page: 1,
      items: [],
      isDisabled: false
    }
  },
  created() {
    this.getTestimoni();
  },
  methods: {
    async getTestimoni (e) {
      let data = await this.$api.get('/api/testimonial', {
          params: {page: this.page, filter: this.filter }
        }).then(res => res)

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.items.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    detail (id) {
      this.$router.push({name: 'TestimoniDetail', params: {id: id}})
    }
  }
})
</script>

<style lang="scss">
.df-testimoni {
  border-bottom: 1px solid #EAEAEA;
  padding: 10px 0;
  .name {
    margin-top: 10px;
  }
  img {
    object-fit: cover;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
}
</style>
